import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeading"
import ProductCard from "../components/ProductCard"
import styled from "styled-components"

import L28DImage from "../images/xl-series/timberline-xl-series-32-disk-25g-26g-tanks.jpg"
import L32DImage from "../images/xl-series/timberline-xl-series-32-disk-25g-26g-tanks.jpg"
import L24OImage from "../images/xl-series/timberline-xl-series-28-orbital-25g-26g-tanks.jpg"
import L28OImage from "../images/xl-series/timberline-xl-series-28-orbital-25g-26g-tanks.jpg"
import L26CImage from "../images/xl-series/timberline-xl-series-30-cylind-25g-26g-tanks.jpg"
import L30CImage from "../images/xl-series/timberline-xl-series-30-cylind-25g-26g-tanks.jpg"

import IndexSection3 from "../components/IndexSection3"

import SEO from "../components/seo"
import Helmet from "react-helmet"

const LpageStyles = styled.div`
  #product-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-items: center;

    @media (max-width: 960px) {
      grid-template-columns: 50% 50%;
    }

    @media (max-width: 650px) {
      grid-template-columns: 100%;
    }
  }
`

const XLseriesPage = () => {
  return (
    <>
      <SEO title="Large Walk Behind Floor Scrubbers | Timberline Cleaning Equipment" />
      <Helmet>
        <title>
          Large Walk Behind Floor Scrubbers | Timberline Cleaning Equipment
        </title>
        <description>
          L-Series Walk-Behind Scrubbers are built for substatial productivity
          with large scrub heads and large clean solution tanks.
        </description>
      </Helmet>
      <MainLayout>
        <PageHeading
          title="Xtra-Large Walk Behind Floor Scrubbers"
          subHeading="XL-Series"
          description="XL-Series Walk-Behind Scrubbers are built for substantial productivity with large scrub heads and large clean solution tanks.."
          path="24 - 32in (61 - 81cm)"
          tank="Sol: 25gal (95l) Rec: 26gal (98l)"
          time="Up to 3.5 hours"
          title1="Cleaning Path:"
          title2="Tank Capacity:"
          title3="Run Time:"
        />
        <LpageStyles>
          <div id="product-container">
            <ProductCard
              image={L28DImage}
              title="XL28 Disk"
              description="Cleaning Path - 28 in / 710 mm"
              link="/products/xl28-disk-scrubber"
            />
            <ProductCard
              image={L24OImage}
              title="XL24 Orbital "
              description="Cleaning Path - 24 in / 610 mm"
              link="/products/xl24-orbital-scrubber"
            />
            <ProductCard
              image={L26CImage}
              title="XL26 Cylindrical"
              description="Cleaning Path - 26 in / 660 mm"
              link="/products/xl26-cylindrical-scrubber"
            />

            <ProductCard
              image={L32DImage}
              title="XL32 Disk"
              description="Cleaning Path - 32 in / 810 mm"
              link="/products/xl32-disk-scrubber"
            />
            <ProductCard
              image={L28OImage}
              title="XL28 Orbital"
              description="Cleaning Path - 28 in / 710 mm"
              link="/products/xl28-orbital-scrubber"
            />
            {/* <ProductCard
              image={L26CImage}
              title="L26 Cylindrical"
              description="Cleaning Path - 26 in / 660 mm"
              link="/products/l26-cylindrical-scrubber"
            /> */}
            <ProductCard
              image={L30CImage}
              title="XL30 Cylindrical"
              description="Cleaning Path - 30 in / 762 mm"
              link="/products/xl30-cylindrical-scrubber"
            />
          </div>
        </LpageStyles>
        <IndexSection3 title="Request a Free Demo at your Facility" />
      </MainLayout>
    </>
  )
}

export default XLseriesPage
